export const environment = {
  production: false,
  maintenance : false,
  URL_PORTAL_UNICO: 'https://portalunico-dev.neuquen.edu.ar',
  API_URL_PORTAL: 'https://portalunico-api-dev.neuquen.edu.ar/api/v1/',//url usuarios
  API_URL: 'https://serviciospublicos-api-dev.neuquen.edu.ar/api/v1/',//url serv publicos dev 
  jwtSecretCodeLogin : '',
  icon:'../assets/images/.ico',

  recaptcha: {
    siteKey: '',
  },

  OFFLINE_AUTH: true
}
